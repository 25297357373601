<template>
  <div class="container" >
    <div class="row">
      <div class="col-12">
        <button class="col-3 float-end btn btn-success" v-on:click="create">Create Project</button>
      </div>
    </div>
  </div>
  <DataTable2
      :headers="headers"
      :actions="actions"
      :api="getProjects"
      :trigger="trigger"
      :fields="['name']"
  />
</template>

<script>
import DataTable2 from 'data-table/src/components/DataTable2'
import {api} from "../services/api";
import InsertModalService from 'modal/src/services/InsertModal'
import router from "../router";
import ModalService from 'modal/src/services/Modal'
import toastService from "toast/src/services/toast";

export default {
  name: "Project",
  components: {DataTable2},
  methods:{
    refresh() {
      if(this.trigger.refresh != null)
        this.trigger.refresh();
    },
    async upsert(project,message){
      await api.projects.post("",project);
      if(!project._id) {
        this.refresh();
      }
      toastService.Notify({
        title:"Success",
        message:`You've successfully ${message} an item.`,
        imgSrc:"/egc.png"
      })
    },
    create() {
      InsertModalService.Show({
        title:`Create Project`,
        type: this.type,
        model: {}
      },(project) => {
        this.upsert(project,"create")
      });
    },
    getProjects(filter,skip,limit) {
      return api.projects.post("get",{
        filter,skip,limit
      });
    },
    edit(project) {
      InsertModalService.Show({
        title:`Edit Project`,
        type: this.type,
        model: project
      },(entity) => {
        this.upsert(entity,"create")
      });
    },
    settings(project) {
      router.push(`/settings/${project._id}`);
    },
    build(project) {
      router.push(`/builds/${project._id}`);
    },
    remove(project) {
      ModalService.Show({
        title:`"Delete Project`,
        message:`Do you really want to delete ${project.name}?`,
      },async () => {
        await api.projects.delete(project._id);
        this.refresh();
        toastService.Notify({
          title:"Success",
          message:"You've successfully delete an item.",
          imgSrc:"/egc.png"
        })
      });
    }
  },
  data() {
    return {
      type :{
        name:"text",
        git:"text",
        script:"textarea",
        postscript:"textarea",
        files:"text",
        builder_id:"text",
        next_build:"text",
        channel_id:"text",
        notificationMessage:"text"
      },
      headers: {
        "_id":"#",
        "name":"Project Name",
      },
      trigger:{},
      actions: [
        {
          classNames:"fa fa-pen text-success",
          callback: this.edit
        },
        {
          classNames:"fa fa-cog text-warning",
          callback: this.settings
        },
        {
          classNames:"fa fa-trash text-danger",
          callback: this.remove
        },{
          classNames:"fa fa-eye",
          callback: this.build
        }
      ]
    }
  }
}
</script>
